import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={24} height={15} viewBox="0 0 24 15" {...props}>
      <title>{"Chevron Up"}</title>
      <path
        d="M22.313 12.617L12.093 2 2 12.617"
        stroke="#44B5E4"
        strokeWidth={3}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
