import React from "react"

function SvgComponent(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
      <title>{"Icon/ArrowUp_48x48"}</title>
      <defs>
        <path id="prefix__a" d="M0 0h16v18H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="#44B5E4" cx={24} cy={24} r={24} />
        <g transform="matrix(-1 0 0 1 32 15)">
          <path
            d="M15.742 6.95a1.301 1.301 0 00-.115-.133L8.953.377a1.37 1.37 0 00-1.888 0h-.001L.39 6.818A1.256 1.256 0 00.523 8.75a1.419 1.419 0 001.769-.187l3.697-3.554c.196-.19.36-.407.488-.644l.18-.387v12.647c-.025.658.45 1.236 1.121 1.36.728.113 1.414-.364 1.532-1.066.012-.074.018-.148.017-.223V3.997l.133.277c.133.27.314.516.534.727l3.691 3.561c.464.47 1.21.548 1.769.187.594-.42.724-1.226.288-1.8"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
