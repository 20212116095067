import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
      <title>{"Icon/Close_48x48"}</title>
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        stroke="#44B5E4"
        strokeWidth={4}
      >
        <path d="M41 39L8 8M7.887 38.879L41.113 8.12" />
      </g>
    </svg>
  )
}

export default SvgComponent
