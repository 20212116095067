import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import GatsbyLink from '../GatsbyLink'
import RenderContent from '../RenderContent'
import { SocialLinks } from '../SocialLinks'
import { FaEnvelope, FaPhone, FaMapPin } from 'react-icons/fa'
import { decodeEntities } from '../../utils/helpers'
import CookiePolicy from '../CookiePolicy'
import { FooterTop } from './FooterTop'
import LoanMonsterLogoInverse from '../SVG/LoanMonsterLogo-Inversed'
import BackToTop from '../SVG/BackToTop'

import './Footer.scss'
class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = { isHomePage: false }
  }
  componentDidMount() {
    const { isHomePage } = this.state
    // check if we are on the home page

    if (this.props.location) {
      const { pathname } = this.props.location
      if (pathname === `/`) {
        this.setState({ isHomePage: !isHomePage })
      }
    }
  }
  sendEmail(event) {
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
    window.location = `mailto:${decodeEntities(email)}`
  }
  render() {
    const { data } = this.props
    const { isHomePage } = this.state
    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      wordpressWpSettings,
    } = data
    const { items: legalNav } = legalNavigation
    const { items: footerNav } = footerNavigation
    const {
      footerTagline,
      navigationInFooter,
      contactDetailsInFooter,
      privacyCookiePolicyNotice,
      privacyCookiePolicyNoticeText,
    } = siteSettings.options
    const { email, phone, addressDisplay } = siteSettings.options.locations[0]

    const { footerPolicyText } = siteSettings.options || ''

    const currentYear = new Date().getFullYear()

    return (
      <>
        <FooterTop />
        <footer>
          <div className="footer-main">
            <div className="inner">
              <div className="wrap">
                <div className="logo-social-copyright">
                  <div className="logos">
                    <Link to="/">
                      <LoanMonsterLogoInverse />
                    </Link>
                    {footerTagline && (
                      <RenderContent
                        className="tagline"
                        content={footerTagline}
                      />
                    )}
                  </div>
                  {siteSettings?.options?.socialLinksFooter && <SocialLinks />}
                  <div className="copyright">
                    <span>
                      © {currentYear} {wordpressWpSettings.title}
                      <br />
                      All Rights Reserved.
                    </span>
                  </div>
                </div>
                {contactDetailsInFooter && (
                  <div className="contact-details">
                    {email && (
                      <div>
                        <a href="#" onClick={event => this.sendEmail(event)}>
                          <FaEnvelope />
                          <span dangerouslySetInnerHTML={{ __html: email }} />
                        </a>
                      </div>
                    )}
                    {addressDisplay && (
                      <div>
                        <FaMapPin />
                        {addressDisplay}
                      </div>
                    )}
                    {phone && (
                      <div>
                        <a
                          href={`tel:${phone.replace(/[^A-Z0-9]/gi, '')}`}
                          className="control-call"
                          aria-label="Call Now"
                        >
                          <FaPhone />
                          {phone}
                        </a>
                      </div>
                    )}
                  </div>
                )}
                {navigationInFooter && (
                  <FooterNavigation footerNav={footerNav} />
                )}
              </div>
              <div className="disclaimers">
                <div className="wrap">
                  {legalNav && (
                    <div className="navigation">
                      <ul>
                        {legalNav.map((item, index) => (
                          <li key={index}>
                            <GatsbyLink to={item.url} decode={true}>
                              {item.title}
                            </GatsbyLink>
                          </li>
                        ))}
                        {/* For SEO reasons, keep the link only active on the homepage */}
                        {isHomePage ? (
                          <li className="bb">
                            <span>
                              Website by{' '}
                              <a
                                rel="noopener"
                                target="_blank"
                                href="https://www.distl.com.au"
                              >
                                Distl
                              </a>
                            </span>
                          </li>
                        ) : (
                          <li className="bb">
                            <span>Website by Distl</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                  <div className="back-to-top-button-wrapper">
                    <BackToTopButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {footerPolicyText &&
            (isHomePage ? (
              <FooterPolicy footerPolicyText={footerPolicyText} />
            ) : null)}
        </footer>
        {privacyCookiePolicyNotice && (
          <CookiePolicy content={privacyCookiePolicyNoticeText} />
        )}
      </>
    )
  }
}

const FooterNavigation = props => {
  const { footerNav } = props
  return (
    <div className="footer-nav">
      {footerNav &&
        footerNav.map((item, index) => {
          return (
            <div
              className={`footer-nav-item${
                item.children ? ' item-parent' : ''
              }`}
              key={index}
            >
              <div className="no-child">
                <GatsbyLink to={item.url} aria-label={item.title} decode={true}>
                  {item.title}
                </GatsbyLink>
              </div>
              {item.children && (
                <ul className="children">
                  {item.children.map((child, index) => {
                    return (
                      <li
                        className={`footer-nav-item_child ${child.classes}`}
                        key={index}
                      >
                        <GatsbyLink
                          to={child.url}
                          aria-label={child.title}
                          decode
                        >
                          {child.title}
                        </GatsbyLink>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          )
        })}
    </div>
  )
}

const BackToTopButton = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return <BackToTop onClick={scrollTop} />
}

const FooterPolicy = ({ footerPolicyText }) => {
  if (!footerPolicyText) return null
  return (
    <div className="footer-policy-text-wrapper">
      <div className="wrap">
        <RenderContent content={footerPolicyText} />
      </div>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerTagline
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
            footerPolicyText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
