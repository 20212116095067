import React from 'react';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import { edgeTest, decodeEntities } from '../../utils/helpers'

export default class GatsbyLink extends React.Component {

  scrollIntoView = (event, containerId) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();
      containerId = containerId.replace('#','')
      const results = document.getElementById(containerId) ? document.getElementById(containerId) : null;
      results && results.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  render() {
    const {to, activeClassName = "active", children, data, decode = false, ...other } = this.props
    if (!to) {
      return <span {...other}>{children}</span>
    }
    if (!to.startsWith('#') && to.includes('#')) {
      // Handles links that include a # but not at the start
      return <Link partiallyActive={true} activeClassName={activeClassName} to={to} onClick={() => navigate(to)} { ...other}>{decode ? decodeEntities(children) : children}</Link>
    }
    if ( to && to.startsWith('#') ) {
      return <span onClick={event => this.scrollIntoView(event, to)} {...other}>{decode ? decodeEntities(children) : children}</span>
    }

    if (to && to.startsWith('/') && edgeTest()) {
      return <Link partiallyActive={true} activeClassName={activeClassName} {...other} to={to}>{decode ? decodeEntities(children) : children}</Link>
    }

    return (
      <a href={to} {...other}>{decode ? decodeEntities(children) : children}</a>
    )
  }
}
