import { useStaticQuery, graphql } from "gatsby"

export const useMonsterPeeking = () => {
  const imageData = useStaticQuery(
    graphql`
      query MonsterPeekingQuery {
        file(relativePath: { eq: "images/monsters/monster-peek.png" }) {
          childImageSharp {
            fixed(width: 200, height: 112, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )
  return imageData.file.childImageSharp.fixed
}