import React, { useState, useEffect } from 'react'

export const getPageSlugFromWpLink = (wpLink, wordPressUrl) => {
  if (!wpLink) return null;
  return wpLink.replace(wordPressUrl, '');
}

export const slugify = (string) => {
  return string.toLowerCase().replace(/ /g, '-')
}

export const isBrowser = typeof window !== `undefined`
export const isClient = typeof window !== 'undefined'

export const scrollTo = (elementY, duration) => {
  if (typeof window !== 'undefined') {
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);
      window.scrollTo({
        top: startingY + diff * percent,
        left: 0,
        behaviour: 'smooth'
      });

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    })
  }
}

export const objectToQuerystring = (obj) => {
  return Object.keys(obj).reduce(function (str, key, i) {
    var delimiter, val;
    delimiter = (i === 0) ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);
    return [str, delimiter, key, '=', val].join('');
  }, '');
}

/* Will pick up the URL query paramaters and turn it into an array of key-value strings */
export const getUrlVars = () => {
  let vars = [], hash;
  const query_string = isClient && window.location.search;

  if (query_string) {
    let hashes = query_string.slice(1).split('&');
    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars[hash[0]] = hash[1];
    }
    return vars;
  } else {
    return false;
  }
}



export const compileQuery = (newKey, newValue = 'null', remove = null) => {
  if (isClient) {
    var params = getUrlVars()
  }
  var queryString = []
  if (params) {
    //Add new
    !params[newKey] && newValue !== 'null' && queryString.push(`${newKey}=${newValue}`)
    //Update existing
    Object.keys(params).forEach(key => {
      var thisValue = newKey === key ? newValue : params[key]
      thisValue !== 'null' && remove !== key && queryString.push(`${key}=${thisValue}`)
    })
  } else {
    //Add new
    newValue !== 'null' && queryString.push(`${newKey}=${newValue}`)
  }

  return queryString.length > 0 ? '?' + queryString.join('&') : ''
}

export const addExternalCSS = (href, id, media = 'all') => {
  if (!isBrowser) {
    return
  }
  if (document.getElementById(id)) {
    return;
  }

  const head  = document.getElementsByTagName('head')[0];
  const link  = document.createElement('link');

  link.id   = id;
  link.rel  = 'stylesheet';
  link.type = 'text/css';
  link.href = href;
  link.media = media;

  head.appendChild(link);
}

export const edgeTest = () => {
  //Edge < 40 bug fix
  const appVersion = isClient && navigator.appVersion.indexOf('Edge') > -1
  const appVersionNumber = appVersion && navigator.appVersion.split(' Edge/')[1].split(' ')[0]

  if (appVersionNumber && parseInt(appVersionNumber) < 17) {
    return false
  }
  return true
}


const htmlEntities = {
    nbsp: ' ',
    cent: '¢',
    pound: '£',
    yen: '¥',
    euro: '€',
    copy: '©',
    reg: '®',
    lt: '<',
    gt: '>',
    quot: '"',
    amp: '&',
    apos: '\''
};

export const decodeEntities = (str) => {
  /* eslint no-useless-escape: 0 */
  /* eslint no-cond-assign: 0 */
  /* eslint no-bitwise: 0 */
  if (!str) return str;
  return str.toString().replace(/\&([^;]+);/g, (entity, entityCode) => {
    let match;
    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode];
    }
    if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
      return String.fromCharCode(parseInt(match[1], 16));
    }
    if (match = entityCode.match(/^#(\d+)$/)) {
        return String.fromCharCode(~~match[1]);
    }
    return entity;
  });
};

/**
 * Returns a trimmed version of the string specified by the max length
 */
export const stringTruncate = (string = '', maxLength = 30, suffix = ' ...') => {
  if (!string) return null;
  if (string.length > maxLength) {
    return (
      `${string.substr(0, maxLength - 1 )}${suffix ? suffix : ''}`
    )
  } else {
    console.log('fn stringLimiter(string, maxLength, suffix) is not running as the string is shorter than the maxLength')
    return string;
  }
}

/**
 * https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
 */
export const isIE11 = typeof window != 'undefined' && !!window.MSStream;

// https://usehooks.com/useWindowSize/
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}