import { useStaticQuery, graphql } from "gatsby"

export const useMonsterLookingOverFixed = () => {
  const imageData = useStaticQuery(
    graphql`
      query MonsterLookingOverQuery {
        file(relativePath: { eq: "images/monsters/monster-looking-over.png" }) {
          childImageSharp {
            fixed(height: 130, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )
  return imageData.file.childImageSharp.fixed
}