import React from 'react'
import './Alert.scss'
import PropTypes from 'prop-types'
const Alert = ({message}) => {
  return (
    <section className="alert">
      <div className="error-message"><p>{message}</p></div>
    </section>
  )
}

Alert.propTypes = {
  message: PropTypes.string
}

Alert.defaultProps = {
  message: 'An error has occurred.'
}

export default Alert;