import React from 'react';
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { addExternalCSS } from '../../utils/helpers';
import ErrorBoundary from '../ErrorBoundary'
import Alert from '../Alert'
import './layout.scss';
import '../../sass/global/styles.scss';

const Layout = ({ children, location, wordpressUrl, includeCss = false, isBlog = false }) => (
	<div id="layout">
		{includeCss && addExternalCSS(`${wordpressUrl}/wp-admin/admin-ajax.php?action=dynamic_css`, 'contrabass')}
		<Helmet title="Home | Loan Monster" />
    <ErrorBoundary render={(error) => <Alert message={error.errorInfo} />}>
		  <Header location={location} />
    </ErrorBoundary>
		  {!isBlog && <main className="main">{children}</main>}
		  {isBlog && <article className="main blog-post">{children}</article>}
		  <Footer location={location} />
	</div>  
)

export default Layout;
