import React from 'react'
import Img from 'gatsby-image'
import MonsterPeek from '../../../images/monsters/monster-peek.png'
import { useMonsterPeeking } from '../../HOOKS/useMonsterPeeking'
import './FooterTop.scss'

export const FooterTop = props => {
  const MonsterPeekingImage = useMonsterPeeking()
  return (
    <div className="footer-top">
      <div className="wrap">
        <div className="image-wrapper">
          <Img alt="monster peeking over" style={{display: 'block'}} fixed={MonsterPeekingImage} />
        </div>
        <div className="footer-top__text">
          <p>We'll get back to you within <span>24hrs</span>, even on the Weekends.</p>
        </div>
      </div>
    </div>
  )
}