import * as React from "react"
import './BurgerStyle.scss'

function SvgComponent(props) {
  return (
    <svg width={46} height={29} viewBox="0 0 46 29" {...props}>
      <title>{"Icon/Hamburger_48x48"}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Desktop/Menu" transform="translate(-53.000000, -50.000000)" stroke="#44B5E4">
          <g id="icon-hamburger" transform="translate(54.000000, 43.000000)">
            <g id="Group" transform="translate(1.833333, 8.708333)">
              <g className="line-group-wrapper" id="Group-2" transform="translate(0.000000, 0.458333)" strokeWidth="4">
                <line x1="5.5" y1="12.375" x2="40.3333333" y2="12.375" id="Line-middle"></line>
                <line x1="0" y1="0.727941176" x2="34.8333333" y2="0.727941176" id="Line-top"></line>
                <line x1="0" y1="24.0220588" x2="34.8333333" y2="24.0220588" id="Line-bottom"></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent